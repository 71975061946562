<template>
  <div class="kecheng">
    <kind-choose-view
      :showKc="false"
      :type="12"
      @isOk="isOk"
      :testPaperType="testPaperType"
    />
    <!-- 学习资料 -->
    <div class="car-two" v-if="list && list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="item-content">
          <img class="item-img" src="@/assets/img/study/fileIcon.png" />
          <div class="item-title">
            <div style="height: 48px" class="item-text twoEllipsis">
              {{ item.title }}
            </div>
            <div class="item-tips elp">
              共{{ item.number ? item.number : 0 }}份资料
            </div>
          </div>
        </div>
        <div class="item-but">
          <div class="elp have" v-if="item.isPurchase == 0 && userInfo">
            已购买
          </div>
          <div class="elp price" v-else>
            <span class="price-icon">￥</span>{{ item.price }}
          </div>
          <div v-if="(item.price > 0 && item.isPurchase == 1) || !userInfo">
            <!-- <div
              class="keep-learn keep-check"
              @click="goOrder(item)"
            >
              立即购买
            </div> -->
            <div class="keep-learn free" @click="toMaterialPage(item)">
              查看资料
            </div>
          </div>
          <div v-else class="keep-learn" @click="toMaterialPage(item)">
            立即查看
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        margin: 40px auto;
        margin-bottom: 80px;
      "
    >
      <el-pagination
        :total="total"
        :current-page="pageNum"
        :page-sizes="[12, 24, 30, 42]"
        :page-size="pageSize"
        :pager-count="5"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import KindChooseView from '@/components/kindChooseViewzl.vue'
import { DataClient } from '@/api/dataClient'
let dataClient = new DataClient()
import { getInfo, getToken } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()

export default {
  components: { KindChooseView },

  data() {
    return {
      list: {},
      search: {},
      pageSize: 12,
      pageNum: 1,
      total: 0,
      userInfo: null,

      testPaperType: null
    }
  },
  created() {
    this.testPaperType = this.$route.query.type
    this.userInfo = getInfo()
    // this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      dataClient
        .getPackageList(
          this.pageSize,
          this.pageNum,
          this.search.kind,
          this.search.zyId,
          this.search.type,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          this.list = res.rows
          this.total = res.total
          if (res.code) {
            this.list = []
            this.total = 0
          }
        })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.getList()
    },
    goOrder(item) {
      let token = getToken()
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem('userId'),
            item.title,
            5,
            item.title,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法查看, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      this.$router.push(`/data/dataPackageDetail?packageId=${item.id}`)
    },
    /* 点击免费试看 */
    toMaterialPage(item) {
      if (this.userInfo) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.id,
            title: item.title,
            schoolId: item.schoolId,
            isPurchase: item.isPurchase,
            num: item.price
          }
        })
      } else {
        this.$notify({
          title: '错误',
          message: '未登录，请去登录',
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  .isNull {
    width: 1200px;
    margin: auto;
    margin-top: 40px;
  }
}
.item {
  padding: 32px;
}
.free {
  border: #dddddd 1px solid;
  color: #666666;
}
</style>
